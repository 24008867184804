/*
=========================================================
* BLK Design System Angular - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-angular
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Core

@import "blk-design-system/custom/functions";
@import "blk-design-system/bootstrap/functions";

@import "blk-design-system/custom/variables";
@import "blk-design-system/bootstrap/variables";

@import "blk-design-system/custom/mixins";
@import "blk-design-system/bootstrap/mixins";

// Bootstrap components

@import "blk-design-system/bootstrap/root";
@import "blk-design-system/bootstrap/reboot";
@import "blk-design-system/bootstrap/type";
@import "blk-design-system/bootstrap/images";
@import "blk-design-system/bootstrap/code";
@import "blk-design-system/bootstrap/grid";
@import "blk-design-system/bootstrap/tables";
@import "blk-design-system/bootstrap/forms";
@import "blk-design-system/bootstrap/buttons";
@import "blk-design-system/bootstrap/transitions";
@import "blk-design-system/bootstrap/dropdown";
@import "blk-design-system/bootstrap/button-group";
@import "blk-design-system/bootstrap/input-group";
@import "blk-design-system/bootstrap/custom-forms";
@import "blk-design-system/bootstrap/nav";
@import "blk-design-system/bootstrap/navbar";
@import "blk-design-system/bootstrap/card";
@import "blk-design-system/bootstrap/breadcrumb";
@import "blk-design-system/bootstrap/pagination";
@import "blk-design-system/bootstrap/badge";
@import "blk-design-system/bootstrap/jumbotron";
@import "blk-design-system/bootstrap/alert";
@import "blk-design-system/bootstrap/progress";
@import "blk-design-system/bootstrap/media";
@import "blk-design-system/bootstrap/list-group";
@import "blk-design-system/bootstrap/close";
@import "blk-design-system/bootstrap/modal";
@import "blk-design-system/bootstrap/tooltip";
@import "blk-design-system/bootstrap/popover";
@import "blk-design-system/bootstrap/carousel";
@import "blk-design-system/bootstrap/utilities";
@import "blk-design-system/bootstrap/print";

// Custom components

@import "blk-design-system/custom/alerts.scss";
@import "blk-design-system/custom/buttons.scss";
@import "blk-design-system/custom/dropdown.scss";
@import "blk-design-system/custom/footer.scss";
@import "blk-design-system/custom/forms.scss";
@import "blk-design-system/custom/images.scss";
@import "blk-design-system/custom/modal.scss";
@import "blk-design-system/custom/navbar.scss";
@import "blk-design-system/custom/type.scss";
@import "blk-design-system/custom/icons.scss";
@import "blk-design-system/custom/tables";
@import "blk-design-system/custom/checkboxes-radio";
@import "blk-design-system/custom/fixed-plugin";
@import "blk-design-system/custom/pagination.scss";
@import "blk-design-system/custom/misc.scss";
@import "blk-design-system/custom/rtl.scss";
@import "blk-design-system/custom/input-group.scss";
@import "blk-design-system/custom/example-pages.scss";
@import "blk-design-system/custom/progress.scss";
@import "blk-design-system/custom/badge.scss";
@import "blk-design-system/custom/pills.scss";
@import "blk-design-system/custom/tabs.scss";
@import "blk-design-system/custom/info-areas.scss";

// Sections
@import "blk-design-system/custom/sections.scss";

// Vendor / Plugins

@import "blk-design-system/custom/vendor/plugin-perfect-scrollbar.scss";
@import "blk-design-system/custom/vendor/plugin-animate-bootstrap-notify.scss";
@import "blk-design-system/custom/vendor/plugin-bootstrap-switch.scss";
@import "blk-design-system/custom/vendor/plugin-nouislider.scss";
@import "blk-design-system/custom/vendor/plugin-datetimepicker.scss";

// Cards

@import "blk-design-system/custom/card";
@import "blk-design-system/custom/cards/card-chart";
@import "blk-design-system/custom/cards/card-map";
@import "blk-design-system/custom/cards/card-user";
@import "blk-design-system/custom/cards/card-task";
@import "blk-design-system/custom/cards/card-plain";
@import "blk-design-system/custom/cards/card-register";
@import "blk-design-system/custom/cards/card-stats";
@import "blk-design-system/custom/cards/card-chart";

// Angular changes
@import "blk-design-system/angular/tabs";
@import "blk-design-system/angular/pills";
@import "blk-design-system/angular/alerts";
@import "blk-design-system/angular/datepicker";
@import "blk-design-system/angular/example-pages";

